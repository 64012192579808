

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 16px;
  /* min-height: 95vh; DOESN'T SEEM TO CHANGE ANYTHING
  height: -webkit-fill-available; */
}


.AppShell {
  /* max-height: -webkit-fill-available; */
  /* height: 90vh; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-Shell-Header {
  background-color: #0DAEFF;
  color: white;
  text-align: left;
  font-size: 2em;
}

.App-Shell-Navbar {
  /* THIS WAS PUSHING THE ABOUT DOWN BELOW min-height: 100vh; */
  height: -webkit-fill-available;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  display: flex;
  flex-direction: column;
  /* position: fixed; */
  justify-content: flex-end;
  align-content: stretch;
  align-items: stretch;
  /* min-height: -webkit-fill-available;
  min-height: 50vh; */
  /* max-height: 40vh; */
  margin: 0px 0px 0px 0px;
  flex-grow: 1;
  font-size: clamp(1rem, 2.5vmin, 3rem);
  -webkit-overflow-scrolling : touch !important;
  /* overflow: auto !important; */
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.gamePrompt {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1.5em;
  font-size: 3.5em;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hint {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 2em;
  font-size: 2.5em;
  padding: 0;
  margin: 0;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.guessInput {
  display: flex;
  /* flex-direction: column; */
  justify-content: right;
  align-items: right;
  min-height: 2em;
  font-size: 2.5em;
  padding: 0px 0px 0px 5px;
  margin: 0px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.guessForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-end;
  max-height: 125px;
  flex-grow: 1;
}

#cursor {
  background: black;
  font-size: .7em;
  margin-left: 2px;
  animation: blink 1.4s step-end 100;
  animation-duration: 1.2s;
  width: 4px;
  height: 1.3em;
}

@keyframes blink {
  00% {background: white}
  50% {background: black}
  100% {background: white}
}

.hintTray {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin: 0px 10px 0px 10px; */
}

.hintKey {
  font-size: clamp(.9rem, 2.5vmin, 3rem);
  border-radius: 4px;
  max-width: 250px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.guessText {
  font-size: clamp(.9rem, 2.5vmin, 2.7rem);
  padding: "0px";
}

.checkmark {
  font-size: clamp(.85rem, 2.5vmin, 2.7rem);
  cursor: pointer;
}

.checkmark:hover {
  cursor: pointer;
}

.key {
  font-size: clamp(.85rem, 2.5vmin, 2.7rem);
  padding: 0px;
  margin: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 40px;
  background-color: #E9E9F2;
  color: black;
  fill: black;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 2px;
  border-width: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
  border-color: none;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.keyboard {
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: clamp(1rem, 3vmin, 1.5rem);
}


.row {
  display: flex;
  justify-content: center;
  min-height: 3.0em;
}

.key.large {
  max-width: 100px;
}

.key.space {
  justify-content: center;
  max-width: 300px;
}

.key.clear {
  justify-content: center;
  max-width: 90px;
}

.key > svg {
  max-width: 100px;
}

@media (hover: hover) and (pointer: fine) {
  .key:hover{
    background-color: lightgray;
    transform: scale(1.06);
  }
}

.key:active {
  background-color: lightgray;
  box-shadow:rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
  transform: translateY(-24px);
  transition-duration: 2ms;
}

.spacer {
  min-width: 13px;
}

.heightSpacer {
  min-height: 30px;
}

.prevGuesses {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-grow: 1;
  min-width: 90%; */
  height: "2em";
}

.unselectable {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}